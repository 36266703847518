<template>
  <div class="error new-page">
    <div class="error__logo"><img src="@/assets/pelmenya_logo_dark.svg" /></div>
    <div class="error__content">
      <div class="error__title">404</div>
      <div class="error__subtitle">Страница не найдена</div>
      <div class="error__text">
        Возможно вы допустили ошибку при вводе адреса или страница удалена.
      </div>
      <router-link
        tag="button"
        :to="{
          name: 'Home',
        }"
        class="error__button"
      >
        Вернуться на главную
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/assets/_variables.scss";
.error {
  background: #ede4d3;
  color: #585854;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__logo {
    margin-top: 45px;
    margin-bottom: 120px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    font-weight: 700;
    font-size: 200px;
    line-height: 150px;
  }
  &__subtitle {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 24px;
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-top: 16px;
    text-align: center;
  }
  &__button {
    width: 343px;
    height: 37px;
    background: #83001b;
    box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039),
      0px 0.85px 3px rgba(0, 0, 0, 0.19);
    border-radius: 50px;
    border: none;
    color: #f6ddaa;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    margin-top: 24px;
    margin-bottom: 125px;
  }
}
@media (max-width: $viewport--md) {
  .error {
    padding-left: 16px;
    padding-right: 16px;
    &__logo {
      margin-top: 25px;
      margin-bottom: 100px;
      img {
        display: none;
      }
    }
    &__title {
      font-size: 100px;
      line-height: 136px;
    }
    &__button {
      margin-bottom: 150px;
    }
  }
}
</style>
